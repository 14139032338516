import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  provideAppCheck,
} from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { firebaseConfig } from '../environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    // provideAppCheck(() => {
    //   const provider = new ReCaptchaEnterpriseProvider(
    //     '6LdWVEIqAAAAAJKYmlUritYeMra1AaSQ0IUcxWTr'
    //   );
    //   if (isDevMode()) {
    //     (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    //   } else {
    //     (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = false;
    //   }

    //   return initializeAppCheck(undefined, {
    //     provider: provider,
    //     isTokenAutoRefreshEnabled: true,
    //   });
    // }),
    provideFirestore(() => getFirestore()),
    // provideAnimationsAsync('noop'),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideClientHydration(),
  ],
};
