import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { firebaseConfig } from '../environment';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavigationBarComponent],
  providers: [HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  ngOnInit(): void {}

  title = 'Aakash Pamnani Blog';
}
