import { Injectable } from '@angular/core';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private firestore: Firestore) {}

  private lastVisible: QueryDocumentSnapshot<DocumentData> | null = null; // Keep track of the last document for pagination
  private pageSize = 10; // Number of posts per page

  async getBlogContent(blogId: string): Promise<DocumentData | any> {
    // return this.firestore
    //   .collection('blogs')
    //   .doc<String>(blogId)
    //   .valueChanges();
    var blogCollection: CollectionReference = collection(
      this.firestore,
      'post_contents'
    );
    return (await getDoc(doc(blogCollection, blogId))).data();
  }

  async getAllBlogs(
    iD?: String
  ): Promise<QueryDocumentSnapshot<DocumentData>[]> {
    const blogCollection: CollectionReference = collection(
      this.firestore,
      'posts'
    );

    // Query to get posts ordered by timestamp (newest first) and limited to pageSize
    var blogQuery = query(
      blogCollection,
      orderBy('timestamp', 'desc'), // Sort by newest first
      limit(this.pageSize)
    );

    if (iD) {
      //match with document id
      blogQuery = query(blogCollection, where('__name__', '==', iD));
    }

    // Fetch the documents
    const blogSnapshot = await getDocs(blogQuery);

    // Store the last visible document for the next page
    this.lastVisible = blogSnapshot.docs[blogSnapshot.docs.length - 1];

    return blogSnapshot.docs;
  }

  async getNextPage(
    lastVisibleData: any
  ): Promise<QueryDocumentSnapshot<DocumentData>[]> {
    if (lastVisibleData == null) {
      return this.getAllBlogs();
    }

    const blogCollection = collection(this.firestore, 'posts');

    // Query for the next page, starting after the last visible document
    const nextQuery = query(
      blogCollection,
      orderBy('timestamp', 'desc'), // Sort by newest first
      startAfter(this.lastVisible), // Start after the last visible document
      limit(this.pageSize) // Limit to pageSize
    );

    // Fetch the documents
    const nextSnapshot = await getDocs(nextQuery);

    // Update the last visible document for future pagination
    this.lastVisible = nextSnapshot.docs[nextSnapshot.docs.length - 1];

    return nextSnapshot.docs;
  }
}
