import {
  Component,
  Inject,
  makeStateKey,
  OnInit,
  PLATFORM_ID,
  TransferState,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from '../services/blog.service';
import parse, { HTMLElement } from 'node-html-parser';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { NavigationBarComponent } from '../navigation-bar/navigation-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UrlPreviewService } from '../services/url-preview.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: [
    './blog-content.component.scss',
    './test.scss',
    './url-preview.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NavigationBarComponent, MatProgressSpinnerModule],
})
export class BlogContentComponent implements OnInit {
  blogHtmlContent: SafeHtml | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState
  ) {}

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      var content = this.transferState.get(
        makeStateKey<String>('content'),
        null
      );
      if (content == null) {
        //redirect to 404
        this.router.navigate(['/']);
        return;
      }
      this.blogHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
        this.transferState
          .get(makeStateKey<String>('content'), String(''))
          .toString()
      );
    }
    this.route.data.subscribe((data) => {
      if (isPlatformServer(this.platformId)) {
        console.log(data);
        var blog = data['blogMeta'][0];
        var root: HTMLElement = parse(data['blogMeta'][1]['content']);

        if (data['blogMeta'] == null || blog!.length == 0 || root == null) {
          //redirect to 404
          this.router.navigate(['/']);
          return;
        } else {
          var title = blog![0].data()['title'];
          var description = blog![0].data()['description'];
          var imageUrl = blog![0].data()['imageUrl'];
          var timestamp = blog![0].data()['timestamp'];

          this.addSeoTags(title, description, imageUrl);

          var article = root!.querySelector('article')!;

          var titleNode = article.querySelector('.graf--h3');
          titleNode?.remove();

          var blogTitle = article.querySelector('h1');
          blogTitle?.setAttribute('class', 'graf--title');

          //first image
          // var firstImage = article.querySelector('img');

          //replace a with preview
          var allUrls = article.querySelectorAll('.graf--mixtapeEmbed');
          allUrls.forEach((u) => {
            this.urlToPreview(u);
          });

          // description
          // var description = article.querySelector('.p-summary');

          var hr = article.querySelectorAll('hr.section-divider');
          hr.forEach((h) => {
            h?.replaceWith(
              "<div class='section-divider'><span class='seperator'></span><span class='seperator'></span><span class='seperator'></span></div>"
            );
          });

          //remove first section-divider
          var firstSectionDivider = article.querySelector('.section-divider');
          firstSectionDivider?.remove();

          //remove first blockquote if it contains membership
          var firstBlockquote = article.querySelector('blockquote');
          if (firstBlockquote) {
            if (
              firstBlockquote?.textContent?.toLowerCase().includes('membership')
            ) {
              firstBlockquote?.remove();
            }
          }

          var allPre = article.querySelectorAll('pre');
          allPre.forEach((p) => {
            var allSpans = p.querySelectorAll('span');
            allSpans.forEach((s) => {
              s?.replaceWith(s?.textContent.trimStart());
            });
          });

          //fotter
          var footer = article.querySelector('footer');
          var allP = footer?.querySelectorAll('p');
          allP?.at(1)?.remove();
          allP?.at(2)?.remove();

          // this.blogHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
          //   article.toString()
          // );
          this.transferState.set(
            makeStateKey<String>('content'),
            article.toString()
          );

          // this.blogHtmlContent = article.toString();

          // this.addSeoTags(
          //   blogTitle?.innerText ?? '',
          //   description?.innerText ?? '',
          //   firstImage?.getAttribute('src') ?? ''
          // );
        }
      }
    });
  }

  addSeoTags(title: string, description: string, imageUrl: string) {
    // Set the title of the page
    this.titleService.setTitle(title + ' | By Aakash Pamnani');

    // Set standard meta tags
    this.metaService.addTags([
      {
        name: 'description',
        content: description,
      },
      { name: 'keywords', content: title.split(' ').join(',') },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Aakash Pamnani' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },

      // Open Graph meta tags (for social media sharing)
      { property: 'og:title', content: title },
      {
        property: 'og:description',
        content: description,
      },
      { property: 'og:image', content: imageUrl },
      { property: 'og:site_name', content: 'Aakash Pamnani' },
      { property: 'og:type', content: 'article' },

      { property: 'title', content: title },
      {
        property: 'description',
        content: description,
      },

      // Twitter card meta tags
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      {
        name: 'twitter:description',
        content: description,
      },
      {
        name: 'twitter:image:src',
        content: imageUrl,
      },
    ]);
  }

  urlToPreview(urlString: HTMLElement) {
    var firstA = urlString.querySelector('a');
    var secondA = urlString.querySelectorAll('a')[1];

    var url = firstA?.getAttribute('href');
    var title = firstA?.querySelector('strong')?.innerText ?? '';
    var subTitle = firstA?.querySelector('em')?.innerText ?? '';

    //extract background image url from style
    var imageUrl = secondA?.getAttribute('style');
    imageUrl = imageUrl?.replace('background-image: url(', '');
    imageUrl = imageUrl?.replace(');', '');
    imageUrl = imageUrl?.replace(/"/g, '');

    urlString.replaceWith(
      this.generateHtmlPreview({
        title: title,
        description: subTitle,
        url: url,
        imageUrl: imageUrl,
      }).toString()
    );
  }

  // Generate HTML string for URL preview
  generateHtmlPreview(data: any): SafeHtml {
    const html = `
<a href="${data.url}" class="url-preview-link" target="_blank">
    <div class="url-preview-container">
        <div class="left-section">
            <h2 class="title">${data.title}</h2>
            <h3 class="description">${data.description}</h3>
        </div>
        <div class="right-section">
            <div style="background-image: url(${data.imageUrl});" alt="URL Thumbnail" class="thumbnail"></div>
        </div>
    </div>
</a>
    `;
    return html; // Sanitize the HTML string
  }
}
