import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { BlogService } from '../services/blog.service';
import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';

export const blogResolver: ResolveFn<
  QueryDocumentSnapshot<DocumentData>[] | null
> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  var blogService = inject(BlogService);
  if (route.paramMap.get('id') == null) return null;
  var blogData = blogService.getAllBlogs(route.paramMap.get('id')!);
  var blogContent = blogService.getBlogContent(route.paramMap.get('id')!);
  return await Promise.all([blogData, blogContent]);
};
