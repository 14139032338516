import { Routes } from '@angular/router';
import { BlogContentComponent } from './blog-content/blog-content.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { blogResolver } from './blog-content/blog-resolver';

export const routes: Routes = [
  {
    path: 'blog/:id',
    component: BlogContentComponent,
    resolve: { blogMeta: blogResolver },
  },
  {
    path: '',
    component: BlogListComponent,
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];
