@if(updatedBlogs.length == 0) {
<div class="loading">
    <mat-spinner [style]="{width: '50px', height: '50px'}"></mat-spinner>
</div>
}
@else {
<div class="list">
    @for (item of updatedBlogs; track $index) {
    <a href="/blog/{{item['id']}}" class="url-preview-link">
        <div class="card">
            <div class="card-content">
                <div class="card-text">
                    <h2 class="title">{{item['title']}}</h2>
                    <p class="description">
                        {{item['description']}}
                    </p>
                    <div class="meta">
                        <span class="date">{{item['date']}}</span>
                    </div>
                </div>
                <div class="card-image">
                    <img src="{{item['imageUrl']}}">
                </div>
            </div>
        </div>

        <!-- <div class="url-preview-container">
            <div class="left-section">
                <h2 class="title">{{item['title']}}</h2>
                <h3 class="description">{{item['description']}}</h3>
            </div>
            <div class="right-section">
                <div alt="URL Thumbnail" class="thumbnail"
                    [style]="{'background-image': 'url(' + item['imageUrl'] + ')'}">
                </div>
            </div>
        </div> -->
    </a>


    }
</div>
}