<!-- @if(!blogHtmlContent && false){
<div class="loading">
    <mat-spinner [style]="{width: '50px', height: '50px'}"></mat-spinner>
</div>
}
@else { -->
<div class="blog-body">

    <div class="blog-content" [innerHTML]="blogHtmlContent">
    </div>
    <!-- <div class="ads"></div> -->

</div>
<!-- } -->