// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyDIJnXOh9JDD3JSqD5xA4eko1PcQXFm4mM',
  authDomain: 'portfolio-1f1b1.firebaseapp.com',
  projectId: 'portfolio-1f1b1',
  storageBucket: 'portfolio-1f1b1.appspot.com',
  messagingSenderId: '247026884024',
  appId: '1:247026884024:web:9a1b1ba7245934254a834d',
  measurementId: 'G-WB13VS62ZS',
};
