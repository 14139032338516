import {
  Component,
  HostListener,
  Inject,
  makeStateKey,
  OnInit,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { BlogService } from '../services/blog.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { RouterModule } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-blog-list',
  standalone: true,
  imports: [MatProgressSpinnerModule, RouterModule],
  templateUrl: './blog-list.component.html',
  styleUrl: './blog-list.component.scss',
})
export class BlogListComponent implements OnInit {
  blogs: Array<QueryDocumentSnapshot<DocumentData>> = [];
  updatedBlogs: Array<any> = [];
  lastVisible: any; // To keep track of the last document for pagination
  isLoading = false;
  isEndOfData = false; // To check if all data has been loaded

  constructor(
    private blogService: BlogService,
    private titleService: Title,
    private metaService: Meta,

    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState
  ) {}

  async ngOnInit(): Promise<void> {
    if (isPlatformServer(this.platformId)) {
      this.addSeoTags(
        'Aakash Pamnani Blog',
        'Flutter, Java, developer. Trying to explain concepts in the most straightforward way possible.',
        'https://blogs.aakashpamnani.in/favicon.png'
      );
    }
    await this.getNextPage(); // Load initial page of blogs
  }

  // Infinite scroll: listen for scroll events
  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isLoading || this.isEndOfData) return;

    // Check if the user is near the bottom of the page (e.g., 100px from bottom)
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 100
    ) {
      this.getNextPage();
    }
  }

  // Fetch the next page of blogs from the server
  async getNextPage(): Promise<void> {
    this.isLoading = true;
    try {
      const newBlogs = await this.blogService.getNextPage(this.lastVisible);
      if (newBlogs.length === 0) {
        this.isEndOfData = true; // No more data to load
      } else {
        this.blogs = [...this.blogs, ...newBlogs];
        this.lastVisible = newBlogs[newBlogs.length - 1]; // Update the last visible document for the next page
        this.updateBlogList();
      }
    } catch (error) {
      // console.trace();
      console.error('Error loading blogs:', error);
    }
    this.isLoading = false;
  }

  // Update the list of blogs to be displayed
  updateBlogList() {
    this.updatedBlogs = [];
    this.blogs.forEach((blog) => {
      this.updatedBlogs.push({
        id: blog.id,
        title: blog.data()['title'],
        description:
          blog.data()['description'] == 'No Description'
            ? ''
            : blog.data()['description'],
        imageUrl: blog.data()['imageUrl'],
        timestamp: blog.data()['timestamp'],
        //convert timestamp to date as 6 June
        date:
          new Date(blog.data()['timestamp']).getDate() +
          ' ' +
          new Date(blog.data()['timestamp']).toLocaleString('en-us', {
            month: 'long',
          }),
      });
    });
  }

  addSeoTags(title: string, description: string, imageUrl: string) {
    // Set the title of the page
    // this.titleService.setTitle(' | By Aakash Pamnani');

    // Set standard meta tags
    this.metaService.addTags([
      {
        name: 'description',
        content: description,
      },
      {
        name: 'keywords',
        content:
          'Flutter, Angular, Software Engineer, Aakash Pamnani, Programmer, Programming, Web Development, Software Development, Mobile Development',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Aakash Pamnani' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },

      // Open Graph meta tags (for social media sharing)
      { property: 'og:title', content: title },
      {
        property: 'og:description',
        content: description,
      },
      { property: 'og:image', content: imageUrl },
      { property: 'og:site_name', content: 'Aakash Pamnani' },
      // { property: 'og:type', content: 'article' },

      { property: 'title', content: title },
      {
        property: 'description',
        content: description,
      },

      // Twitter card meta tags
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      {
        name: 'twitter:description',
        content: description,
      },
      {
        name: 'twitter:image:src',
        content: imageUrl,
      },
    ]);
  }
}
